<template>
  <section>

    <v-card class="my-4 rounded-lg border" elevation="0">
      <v-card-title class="d-flex justify-space-between align-center">
        <div>
          <span class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12">{{ $t("Question") }}</span>
        </div>
        <div>
          <span class="f13 font-weight-light"> {{ question.createDate | moment("from", "now") }} </span>
        </div>
      </v-card-title>

      <v-card-text>
        <p class="f14 text-justify" v-html="question.question"></p>
      </v-card-text>
    </v-card>

    <v-card class="my-4 mx-4 rounded-lg border" elevation="0" v-for="(answer, index) in question.answers" :key="index">
      <v-card-title class="d-flex justify-space-between align-center" elevation="0">
        <div>
          <span class="green lighten-5 green--text text--darken-1 p-2 rounded-lg f12">{{ $t("Answer") }} {{index+1}}</span>
        </div>
        <div>
          <span class="f13 font-weight-light">{{ answer.createDate | moment("from", "now") }}</span>

          <v-menu offset-y bottom rounded auto v-if="answer.doctorId == $store.getters.doctorId">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small class="ms-2 me-0" color="grey" v-bind="attrs" v-on="on">
                <v-icon color="grey darken-1">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item ripple :to="`/s/questions/${question.id}/edit/${answer.id}`">
                <span class="f13">{{ $t('Edit') }}</span>
              </v-list-item>
              <v-list-item ripple @click="deleteAnswer(answer)">
                <span class="f13">{{ $t('Delete') }}</span>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

      </v-card-title>

      <v-card-text>
        <p class="f14" v-html="answer.answer"></p>
      </v-card-text>

      <v-divider dark class="m-0"></v-divider>

      <v-card-actions class="d-flex justify-space-between align-items-center">
        <div>
          <v-avatar size="36">
            <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="answer.doctorProfilePic"></v-img>
          </v-avatar>
          <span class="ms-2 font-weight-bold f13">{{ answer.doctorFullName }}</span>
        </div>

        <v-btn v-if="answer.liked" flat depressed small class="h-auto py-1" color="primary" @click="setUnlike(answer, index)">
          <div class="d-flex d-inline-flex align-items-center">
            <iconly type="bold" name="heart" class="ms-1 me-0 wh-20" />
            <span v-if="answer.userLikeAnswers && answer.userLikeAnswers.length" class="me-1 text-white f12">
              {{
                  answer.userLikeAnswers.length
              }}
            </span>
            <span v-else class="me-1 text-white f12">0</span>
          </div>
        </v-btn>

        <v-btn v-else flat outlined small class="h-auto py-1 border border-primary" @click="setLike(answer, index)">
          <div class="d-flex d-inline-flex align-items-center primary--text">
            <iconly type="bold" name="heart" class="ms-1 me-0 wh-20"></iconly>
            <span v-if="answer.userLikeAnswers && answer.userLikeAnswers.length" class="me-1 f12">
              {{
                  answer.userLikeAnswers.length
              }}
            </span>
            <span v-else class="me-1 f12">0</span>
          </div>
        </v-btn>

      </v-card-actions>
    </v-card>

    <v-footer app fixed elevation="0" class="mb-4" color="transparent">
      <v-container fluid>
        <v-card rounded="lg" class="d-flex flew-row justify-space-between align-items-center px-2 py-1 border" elevation="1">
          <v-btn color="primary" rounded large class="my-1" :to="`/s/questions/${question.id}/answer`">
            {{ $t("Add your Answer") }}
          </v-btn>

          <div class="d-flex align-items-center">
            <v-card class="mx-1 px-3 py-1 d-flex align-items-center border" flat>
              <span class="me-2 ms-0 f14 grey--text text--darken-2">{{question.userViews.length}}</span>
              <iconly type="light" name="show" color="#20B1EA" />
            </v-card>
            
            <v-card v-if="question.liked" class="mx-1 px-3 py-1 d-flex align-items-center border" color="primary" flat @click="setUnlikeQuestion(question)">
              <span class="me-2 ms-0 f14 white--text">{{question.userLikeQuestions.length}} </span>
              <iconly type="bold" name="heart" color="#FFFFFF" />
            </v-card>
            <v-card v-else class="mx-1 px-3 py-1 d-flex align-items-center border" flat @click="setLikeQuestion(question)">
              <span class="me-2 ms-0 f14 grey--text text--darken-2">{{question.userLikeQuestions.length}} </span>
              <iconly type="light" name="heart" color="#20B1EA"/>
            </v-card>
          </div>
        </v-card>
      </v-container>
    </v-footer>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();

export default {
  data() {
    return {
      questionId: null,
      question: {
        answers: [],
        userLikeQuestions: [],
        userViews: []
      },
    };
  },
  methods: {
    getQuestionDetails(id) {
      apiService.getQuestionDetails(id)
        .then((response) => {
          this.question = response.data;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },
    deleteAnswer(answer) {
      this.$swal({
        icon: "warning",
        text: this.$t('Are you sure you want to delete this item?'),
        confirmButtonText: this.$t('Yes'),
        showDenyButton: true,
        denyButtonText: this.$t('No'),
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.submitDeleteAnswer(answer);
          }
        });
    },
    submitDeleteAnswer(answer) {
      apiService.deleteAnswer(answer.id)
        .then(() => {
          this.$swal({
            icon: "success",
            text: this.$t('Your answer was delete successfully'),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.getQuestionDetails(this.questionId);
              }
            });
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
        });
    },
    setUnlike(answer, index) {
      apiService.unlikeAnswer(answer.id, "DOCTOR")
      .then(() => {
          this.question.answers[index].liked = false;
          this.question.answers[index].userLikeAnswers.pop();
      });
    },
    setLike(answer, index) {
      apiService.likeAnswer(answer.id, "DOCTOR")
      .then(() => {
          this.question.answers[index].liked = true;
          this.question.answers[index].userLikeAnswers.push({});
      });
    },
    setUnlikeQuestion(question) {
      apiService.unlikeQuestion(question.id, "DOCTOR")
          .then(() => {
            this.question.liked = false;
            this.question.userLikeQuestions.pop();
          });
    },
    setLikeQuestion(question) {
      apiService.likeQuestion(question.id, "DOCTOR")
          .then(() => {
            this.question.liked = true;
            this.question.userLikeQuestions.push({});
          });
    },
    setViewQuestion(id) {
      apiService.setViewQuestion(id, 'DOCTOR');
    },
  },
  mounted() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
    this.questionId = this.$route.params.questionId;
    this.getQuestionDetails(this.questionId);
    this.setViewQuestion(this.questionId);
  },
};
</script>
<style lang="css" scped>
</style>